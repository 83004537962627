/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "colors_primary": "bg-black hover:bg-gray-700 active:bg-gray-800 focus:ring-secondary-200 text-white",
  "colors_secondary": "bg-gray-100 hover:bg-gray-200 active:bg-gray-300 focus:ring-secondary-200 text-black",
  "size_small": "px-5 py-2 gap-1.5 text-headline-xs",
  "size_medium": "px-6 py-2.5 gap-2.5 text-headline-sm",
  "size_large": "px-6 py-3 gap-2.5 text-headline-sm",
  "variant_default": "inline-flex items-center justify-center rounded-full ring-0 active:ring-0 hover:ring-0 focus:ring-4 outline-none focus:outline-none transition-all duration-200 ease-in-out border"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "Button");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;